<template>
  <div class="fast-body-modo-aluno">
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container fast-plataforma-iuea-hide">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide mb-0">
            <li class="fd-app-breadcrumb-item">
              <a href="/minhas-plataformas" class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')">Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a :href="'/plataforma/' + $route.params.id_plataforma" class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)">Plataforma {{
                  $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a class="aluno_font_color" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Secretaria</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content mt-0">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="mt-3 mb-4">
                <a class="btn-novo btn-red-hollow-novo" :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')">
                  <b-icon-arrow-return-left /> Voltar
                </a>
              </div>
               <!-- fd-app-welcome -->
              <section class="fd-app-welcome-novo text-left fast-plataforma-iuea-hide">
                <div>
                  <h2 class="aluno_font_color mb-0">
                    <span>Secretaria</span>
                  </h2>
                  <p class="aluno_font_color my-0">Solicite e acompanhe seu documento!</p>
                  <div>
                    <img :src="require('@/assets/images/separador.png')"  />
                  </div>
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <!-- fd-mode-opcoes -->
              <section class="col-md-12 col-sm-12 col-lg-5 fd-mode-opcoes fd-mode-ead">
                <!-- meus documentos -->
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-documentos'"
                  class="row btn-menu btn-menu-blue p-2 mb-4"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-documentos')">
                  <img class="ml-3" style="max-height: 50px;"
                    :src="require('@/assets/images/app/meus-documentos.png')">
                  <h2 class="col-8 font-weight-normal" style="letter-spacing: -1px;">MEUS
                    DOCUMENTOS</h2>
                </a>
                <!-- Meus requerimentos -->
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-requerimentos'"
                  class="row btn-menu btn-menu-blue p-2 mb-4"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-requerimentos')">
                  <img class="ml-3" style="max-height: 50px;"
                    :src="require('@/assets/images/app/meus-requerimentos.png')">
                  <h2 class="col-8 font-weight-normal">MEUS REQUERIMENTOS</h2>
                </a>
                <!-- Minhas ocorrências -->
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-ocorrencias'"
                  class="row btn-menu btn-menu-blue p-2 mb-4"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-ocorrencias')">
                  <img class="ml-3" style="max-height: 50px;"
                    :src="require('@/assets/images/app/minhas-ocorrencias.png')">
                  <h2 class="col-8 font-weight-normal">MINHAS OCORRÊNCIAS</h2>
                </a>
                <!-- Extrato de parcelas pagas -->
                <a :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/extrato-parcelas'"
                  class="row btn-menu btn-menu-blue p-2 mb-4"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/extrato-parcelas')">
                  <img class="ml-3" style="max-height: 50px;"
                    :src="require('@/assets/images/app/extrato-parcelas.png')">
                  <h2 class="col-8 font-weight-normal">DEMONSTRATIVO IR</h2>
                </a>
              </section>
              <!-- /fd-mode-opcoes -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modals -->

  </div>
</template>
  
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoAlunoSecretaria",
  components: {
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },

  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          // Se foi personalizado aplica cor nos textos
          if (this.$store.state.fastPersonalizacao.aluno_font_color) {
            Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
          }
          // Se foi personalizado aplica cor nos textos dos menus
          if (this.$store.state.fastPersonalizacao.menu_font_color) {
            Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
          }

          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });

    }
  },
  methods: {

  },
};
</script>
  
<style></style>